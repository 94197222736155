<template>
  <div>
    <p>Ошибка</p>
    <v-btn to="/login">Войти</v-btn>
    <v-btn to="/">На главную</v-btn>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped>

</style>
